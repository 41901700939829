import React, { Fragment, useEffect, useState } from "react"
import Breadcrumb from "../components/Breadcrumb"
import Hero from "../components/Hero"
import TitleBlock from "../components/TitleBlock"
import Layout from "../components/Layout"
import Directive from "../components/Directive"
import {
  presidente,
  vicepresidente,
  secretario,
  Tesorero,
  Vocales,
  Asesores,
} from "../data/directive-data"
import parse from "react-html-parser"
import TitlePage from "../components/TitlePage"
import { useAxiosClient } from "../service/useAxiosClient"

export default function OrganizationalStructure({ location }) {
  const [organizationStructure, setOrganizationStructure] = useState([]);
  const [, triggerEventFiltered] = useAxiosClient({}, { manual: true })
  const path = [
    { label: "Inicio", to: "/" },
    { label: "Quiénes somos - Estructura organizativa" },
  ]

  const fetchComite = () => {
    triggerEventFiltered({
      url: '/web-configs/organization-structure/',
      params: {
        contain_match: 'estructura_organizativa_comite'
      }
    })
      .then(response => setOrganizationStructure(response.data));
  }

  const renderLeftColumn = () => {
    return (
      organizationStructure.length > 0 &&
      organizationStructure.slice(0, 3).map(item => (
        <Fragment>
          <Directive
            titleHtml={item.description}
            icon="fas fa-users"
            className="OrganizationalStructure-directive"
          />
          {parse(item.value)}
        </Fragment>
      ))
    )
  }

  const renderRightColumn = () => {
    return (
      organizationStructure.length > 0 &&
      organizationStructure.slice(3, 5).map(item => (
        <Fragment>
          <Directive
            titleHtml={item.description}
            icon="fas fa-users"
            className="OrganizationalStructure-directive"
          />
          {parse(item.value)}
        </Fragment>
      ))
    )
  }

  useEffect(() => {
    fetchComite();
  }, [])
  return (
    <Layout>
      <TitlePage title="Estructura organizativa" url={location.pathname} />
      <Hero image="/imgs/quienes-somos.jpg" text="Quiénes somos" />
      <div className="container950">
        <div className="OrganizationalStructure">
          <Breadcrumb
            path={path}
            className="OrganizationalStructure-breadcrumb"
          />
          <TitleBlock title="Estructura organizativa" icon="fas fa-sitemap" />
          <TitleBlock
            title="Junta directiva"
            icon="fas fa-layer-group"
            className="OrganizationalStructure-subtitle"
          />
          <p className="OrganizationalStructure-text">
            EFPA España se rige por una Junta Directiva que representa y dirige
            a los miembros certificados adheridos a la asociación. Además,
            existen diversos comités de trabajo, que estudian aspectos
            específicos que interesan al sector y a sus profesionales.
          </p>
          <div className="OrganizationalStructure-wrapper">
            <div className="row efpa-row">
              <div className="OrganizationalStructure-row">
                <Directive
                  icon="fas fa-user-tie"
                  title="Presidente"
                  names={presidente}
                />
                <Directive
                  icon="fas fa-user-tie"
                  title="Vicepresidente"
                  names={vicepresidente}
                />
              </div>
              <div className="OrganizationalStructure-row">
                <Directive
                  icon="fas fa-user-tie"
                  title="Secretario"
                  names={secretario}
                />
                <Directive
                  icon="fas fa-user-tie"
                  title="Tesorero"
                  names={Tesorero}
                />
              </div>
              <div className="OrganizationalStructure-row">
                <Directive
                  icon="fas fa-users"
                  title="Vocales"
                  names={Vocales}
                />
              </div>
              <div className="OrganizationalStructure-row border-0">
                <Directive
                  icon="fas fa-users"
                  title="Asesores"
                  names={Asesores}
                />
              </div>
            </div>
          </div>
          <TitleBlock
            title="Secretariado"
            icon="fas fa-layer-group"
            className="OrganizationalStructure-subtitle"
          />
          <span className="OrganizationalStructure-text margin">
            Andrea Carreras-Candi, Dirección de EFPA España.
          </span>
          <TitleBlock
            title="Comites"
            icon="fas fa-layer-group"
            className="OrganizationalStructure-subtitle"
          />
          <div className="OrganizationalStructure-columns">
            <div className="row efpa-row">
              <div className="col-12 col-md-6 OrganizationalStructure-side">
                {renderLeftColumn()}
              </div>
              <div className="col-12 col-md-6 OrganizationalStructure-side">
                {renderRightColumn()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
