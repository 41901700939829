import React from "react"
import PropTypes from "prop-types"
import Item from "./item"
import parse from "react-html-parser"

function Breadcrumb(props) {
  const { path, className } = props

  const renderPath = () => {
    const lastItem = path.length - 1

    return path.map((item, index) => (
      <Item
        key={`brc-item-${index}`}
        label={ parse(item.label) }
        state={item.state}
        to={item.to}
        last={lastItem === index}
      />
    ))
  }

  return <div className={`Breadcrumb ${className}`}>{renderPath()}</div>
}

Breadcrumb.propTypes = {
  path: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.string,
    })
  ),
  className: PropTypes.string,
}

Breadcrumb.defaultProps = {
  path: [],
  className: "",
}

export default Breadcrumb
