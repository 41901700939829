import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

function Item(props) {
  const { label, to, last, state } = props

  const renderDefault = () => (
    <Fragment>
      <Link to={to} className="Breadcrumb-item" state={state}>
        {label}
      </Link>
      <span className="Breadcrumb-separator">{">"}</span>
    </Fragment>
  )

  const renderLast = () => <span className="Breadcrumb-item">{label}</span>

  return last ? renderLast() : renderDefault()
}

Item.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string,
  last: PropTypes.bool,
  state: PropTypes.shape,
}

export default Item
