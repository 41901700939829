import React from "react"
import PropTypes from "prop-types"

export default function Hero(props) {
  const { className, image, text } = props
  return (
    <section className={`Hero ${className}`}>
      <div className="Hero-relative">
        <img className="Hero-image" src={image} alt="" />
        <div className="Hero-text">
          <p>{text}</p>
        </div>
      </div>
    </section>
  )
}

Hero.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string,
}

Hero.defaultProps = {
  className: "",
  text: "",
  image: "",
}
