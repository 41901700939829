import React, { Fragment } from "react"
import propTypes from "prop-types"
import parse from "react-html-parser"

export default function TitleBlock(props) {
  const { className, title, icon, subtitle, noBorder } = props

  return (
    <Fragment>
      <h2
        className={`TitleBlock ${subtitle ? "withSubtitle" : ""} ${
          noBorder ? "noBorder" : ""
        } ${className}`}
      >
        {icon && <i className={icon}></i>}
        {parse(title)}
      </h2>
      {subtitle && <span className="TitleBlock-subtitle">{subtitle}</span>}
    </Fragment>
  )
}

TitleBlock.propTypes = {
  className: propTypes.string,
  title: propTypes.string,
  icon: propTypes.string,
  subtitle: propTypes.string,
  noBorder: propTypes.bool,
}

TitleBlock.defaultProps = {
  className: "",
  title: "",
  icon: "",
}
