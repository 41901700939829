import React from "react"
import PropTypes from "prop-types"
import parse from "react-html-parser"
import styled from 'styled-components'

const HtmlParsedTitle = styled.div`
  &> p {
      font-weight: 700;
      margin-top: 6px;
      display: block;
      font-family: sans-serif;
      color: #112149;
  }
`;

const Directive = props => {
  const { title, names, icon, className, titleHtml } = props

  const renderNames = () => {
    if (!names || !names.length) return

    return names.map((item, index) => (
      <li key={`directive-${index}`} className="Directive-name">
        {item}
      </li>
    ))
  }

  return (
    <div className={`Directive ${className}`}>
      <span className="Directive-icon">
        <i className={icon} style={{ color: '#112149'}}></i>
      </span>
      {titleHtml ? <HtmlParsedTitle>{parse(titleHtml)}</HtmlParsedTitle> : <span className="Directive-title">{title}</span>}
      <ul className="Directive-names">{renderNames()}</ul>
    </div>
  )
}

Directive.propTypes = {
  names: PropTypes.array.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string,
  classNeme: PropTypes.string,
}

Directive.defaultProps = {
  className: "",
}

export default Directive
